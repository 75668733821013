@import './styles.scss';

.ui-denounce-profile {
  margin: 1em 1em 2em 0;

  &_content {
    display: flex;
    gap: 8px;
  }

  &-link,
  &-wordings {
    margin-left: 8px;
  }

  .andes-card__content {
    padding: 12px 22px 11px 16px !important;
    line-height: 0;
    display: -webkit-box;
  }
}
