@import '../../../styles/colors';
@import '../../../styles/common';
@import '../../../styles/variables';
@import '../../../styles/shared';
@import '~@andes/card/index';
@import '~@andes/carousel-snapped/index';
@import '~@andes/button/index';
@import '~@andes/modal/index';
@import '~@andes/snackbar/index';
@import '~@polycard/card/build';
@import 'eshops-components-library/src/components/ProfileHeader/lib/desktop';
@import 'eshops-components-library/src/components/ProfileHeader/lib/mobile';
@import '../../../components/TitleList/styles';
@import '../../../components/Header/styles';
@import '../../../components/ListsComponent/styles.scss';
@import '../../../components/DenunceProfile/styles.mobile.scss';
@import '../../../components/MainTitle/styles';
@import '../../../components/EmptyState/styles.scss';

.home-lists {
  margin: 24px auto auto 16px;

  .title-list_container {
    box-sizing: border-box;
    justify-content: space-between;
    padding: 0 16px 0 0;

    h1 {
      font-size: 14px;
    }

    a {
      font-size: 12px;
    }
  }

  &_container {
    .poly-card--grid-card.poly-card--mobile .poly-card__portada--grid-height,
    .poly-card--grid.poly-card--mobile .poly-card__portada--grid-height {
      height: auto;
    }
  }

  .andes-carousel-snapped__slide {
    height: auto;
  }
}
